export const theme = {
    tokens: {
        //General
        colorBackgroundLayoutMain: '#eee',
        colorTextAccent: '#e30613',
        colorTextHeadingDefault: '#e30613',
        //Side Navigation Toggle
        colorBackgroundLayoutToggleDefault: '#e30613',
        colorBackgroundLayoutToggleHover: '#eee',
        colorBackgroundLayoutToggleActive: '#383838',
        //Primary Button
        colorBackgroundButtonPrimaryDefault: '#e30613',
        colorTextButtonPrimaryDefault: 'white',
        colorBackgroundButtonPrimaryHover: '#383838',
        colorBackgroundButtonPrimaryActive: '#eee',
        colorTextButtonPrimaryActive: 'white',
        //NormalButton
        colorBorderButtonNormalDefault: '#e30613',
        colorTextButtonNormalDefault: '#e30613',
        colorBorderButtonNormalHover: '#383838',
        colorBackgroundButtonNormalHover: 'white',
        colorTextButtonNormalHover: '#383838',
        colorBorderButtonNormalActive: '#eee',
        colorTextButtonNormalActive: '#eee',
        colorBackgroundButtonNormalActive: '#e30613',
    },
    contexts: {
        'top-navigation': {
            tokens: {
                //General
                colorTextAccent: '#383838',
                colorTextTopNavigationTitle: '#e30613',
                colorBackgroundContainerContent: '#eee',
                //Primary Button
                colorBackgroundButtonPrimaryDefault: '#e30613',
                colorBackgroundButtonPrimaryHover: '#383838',
                colorTextButtonPrimaryHover: 'white',
                colorTextButtonPrimaryDefault: 'white',
                colorBackgroundButtonPrimaryActive: '#e30613',
                colorTextButtonPrimaryActive: 'white',
                //Menu                
                colorTextInteractiveDefault: '#383838',
                colorTextInteractiveHover: '#e30613',
                colorBackgroundDropdownItemDefault: '#eee',               
            },
        },
        'header': {
            tokens: {
                //General
                colorTextAccent: '#e30613',
                colorTextLinkDefault: '#eee',
                colorTextLinkHover: '#e30613',
                colorBackgroundLayoutMain: '#383838',
                colorTextHeadingDefault: '#eee',
                colorTextHeadingSecondary: '#eee',
                colorTextBreadcrumbCurrent: '#e30613',
                colorTextBreadcrumbIcon: '#eee',
                //Primary Button
                colorBackgroundButtonPrimaryDefault: '#e30613',
                colorBackgroundButtonPrimaryHover: 'white',
                colorBackgroundButtonNormalDefault: '#383838',
                colorTextButtonPrimaryDefault: 'white',
                //Normal Button 
                colorTextButtonNormalDefault: 'white',
                colorBorderButtonNormalDefault: 'white',
                colorTextButtonNormalHover: '#e30613',
                colorBorderButtonNormalHover: '#e30613',
            },
        },
        'flashbar': {
            tokens: {
                colorTextAccent: '#e30613',
            },
        },
        'alert': {
            tokens: {
                colorTextAccent: '#e30613',
            },
        },
    },
}