import { Container, ContentLayout, Header, SpaceBetween } from "@cloudscape-design/components";
import { AppHeader } from "../../commons/header";
import React from "react";

export default function Pillars() {

    return (
        <ContentLayout
            header={
                <AppHeader
                    breadcrumbs={[
                        { text: "CloudFellow", href: "/" },
                        { text: "Onboarding", href: "/onboarding" },
                        { text: "Pillars", href: "/pillars" }
                    ]}
                    header={"Cloud Governance Pillars"}
                    description={"Hier werden alle Disziplinen der Cloud Governance beschrieben, welche wir auch bei der RheinEnergie so anwenden."}
                    actions={null}
                />
            }>
            <SpaceBetween direction="vertical" size="xs">
                <Container
                    header={<Header>Cost Management</Header>}
                >
                    Die Cost Management Baseline in der Cloud-Governance ist ein Rahmenwerk, das sicherstellt, dass die Ausgaben für Cloud-Ressourcen optimiert und kontrolliert werden. Sie legt Richtlinien fest, um unnötige Kosten zu vermeiden und Ressourcen effizient zu nutzen. Durch die Implementierung dieser Baseline können Unternehmen ihre Cloud-Ausgaben besser planen, überwachen und optimieren, um Budgetüberschreitungen zu vermeiden und die Gesamtkosten zu senken. Dies trägt dazu bei, die Wirtschaftlichkeit und Rentabilität der Cloud-Nutzung zu verbessern und die finanzielle Gesundheit des Unternehmens zu fördern.
                </Container>
                <Container
                    header={<Header>Security Baseline</Header>}
                >
                    Die Security Baseline in der Cloud-Governance ist ein Rahmenwerk, das sicherstellt, dass die Sicherheitsstandards und -richtlinien in einer Cloud-Umgebung eingehalten werden. Sie definiert grundlegende Sicherheitsmaßnahmen wie Zugriffskontrolle, Verschlüsselung und Überwachung, um Daten vor Bedrohungen zu schützen. Diese Baseline hilft, Sicherheitslücken zu identifizieren und zu schließen, um die Integrität, Vertraulichkeit und Verfügbarkeit der Daten zu gewährleisten. Durch die Implementierung dieser Maßnahmen können Unternehmen ihre Cloud-Infrastruktur besser absichern und das Risiko von Datenschutzverletzungen oder Cyberangriffen minimieren.
                </Container>
                <Container
                    header={<Header>Identity Baseline</Header>}
                >
                    Die Identity Baseline in der Cloud-Governance ist eine Grundlage, die sicherstellt, dass die Identitäten von Benutzern und Ressourcen in einer Cloud-Umgebung korrekt verwaltet werden. Sie legt fest, wer auf welche Ressourcen zugreifen kann und welche Berechtigungen sie haben. Diese Baseline hilft, Sicherheitsrisiken zu minimieren, indem sie die Zugriffsrechte streng kontrolliert und sicherstellt, dass nur autorisierte Personen oder Systeme auf die Daten zugreifen können. Sie bildet somit eine wesentliche Komponente für den Schutz sensibler Informationen in der Cloud.
                </Container>
                <Container
                    header={<Header>Resource Consistency</Header>}
                >
                    Die Ressourcenkonsistenz in der Cloud-Governance stellt sicher, dass alle in einer Cloud-Umgebung bereitgestellten Ressourcen einheitlich und effizient verwaltet werden. Sie legt Standards fest, um sicherzustellen, dass Ressourcen gemäß den Unternehmensrichtlinien und Best Practices eingerichtet und konfiguriert werden. Diese Baseline hilft, Inkonsistenzen oder Konfigurationsfehler zu vermeiden, die die Leistung, Sicherheit und Zuverlässigkeit der Cloud-Infrastruktur beeinträchtigen könnten. Durch die Implementierung dieser Standards können Unternehmen eine konsistente und zuverlässige Bereitstellung ihrer Ressourcen in der Cloud gewährleisten und so Betriebsabläufe optimieren und Risiken minimieren.
                </Container>
                <Container
                    header={<Header>Deployment Acceleration</Header>}
                >
                    Die Deployment-Beschleunigung in der Cloud-Governance bezieht sich auf Strategien und Prozesse, um die Geschwindigkeit und Effizienz bei der Bereitstellung von Anwendungen und Diensten in der Cloud zu erhöhen. Sie umfasst Automatisierungstools, Vorlagen und bewährte Methoden, um den Bereitstellungsprozess zu rationalisieren und zu beschleunigen. Diese Baseline zielt darauf ab, die Time-to-Market zu verkürzen, indem sie Entwicklern und Teams ermöglicht, schnell und konsistent neue Funktionen und Updates in die Cloud umzusetzen. Durch die Implementierung beschleunigter Bereitstellungsmethoden können Unternehmen ihre Agilität steigern, Innovationen fördern und Wettbewerbsvorteile erlangen.
                </Container>
            </SpaceBetween>
        </ContentLayout>
    );
}
