import { BreadcrumbGroup, BreadcrumbGroupProps, Header } from "@cloudscape-design/components";
import React from "react";

export function AppHeader(props: { actions: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; description: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; breadcrumbs: readonly BreadcrumbGroupProps.Item[]; header: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; }) {
    return (
      <Header variant="h1"
        actions={
          props.actions
        }
        description={props.description}>
        <BreadcrumbGroup items={props.breadcrumbs}></BreadcrumbGroup>
        {props.header}
      </Header>
    );
}