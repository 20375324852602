import { Alert, Box, Button, ContentLayout, Form, FormField, Header, Input, Modal, SpaceBetween, Spinner, Table, Textarea } from "@cloudscape-design/components";
import { AppHeader } from "../../commons/header";
import React from "react";
import DatabaseService from "../../services/databaseservice";

export default function AdminFAQ() {
    const [visibleCreate, setVisibleCreate] = React.useState(false);
    const [visibleModify, setVisibleModify] = React.useState(false);
    const [visibleDelete, setVisibleDelete] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const [question, setQuestion] = React.useState("");
    const [explanation, setExplanation] = React.useState("");
    const [faqid, setFAQID] = React.useState("");

    function openCreateModal() {
        setExplanation("")
        setQuestion("")
        setVisibleCreate(true)
    }

    return (
        <ContentLayout
            header={
                <AppHeader
                    breadcrumbs={[
                        { text: "CloudFellow", href: "/" },
                        { text: "Administration Area", href: "/" },
                        { text: "FAQ", href: "/faq" }
                    ]}
                    header={"Frequently Asked Questions bearbeiten"}
                    description={"Hier kannst du das FAQ bearbeiten."}
                    actions={null}
                />
            }>
            <Table
                resizableColumns
                columnDefinitions={[
                    {
                        id: "question",
                        header: "Frage",
                        cell: item => item.data().question || "-",
                        sortingField: "question",
                    },
                    {
                        id: "explanation",
                        header: "Erklärung",
                        cell: item => item.data().explanation || "-",
                        sortingField: "explanation"
                    },
                    {
                        id: "actions",
                        header: "Actions",
                        cell: item => <SpaceBetween direction="horizontal" size={"s"}>
                            <Button onClick={() => {
                                setExplanation(item.data().explanation)
                                setQuestion(item.data().question)
                                setFAQID(item.id)
                                setVisibleModify(true)
                            }}>Modify</Button>
                            <Button onClick={() => {
                                setFAQID(item.id)
                                setQuestion(item.data().question)
                                setVisibleDelete(true)
                            }}>Delete</Button>
                        </SpaceBetween>
                    }
                ]}
                items={DatabaseService.getFAQ()}
                loadingText="Loading resources"
                sortingDisabled
                empty={
                    <Box
                        margin={{ vertical: "xs" }}
                        textAlign="center"
                        color="inherit"
                    >
                        <SpaceBetween size="m">
                            <b>Keine Fragen vorhanden</b>
                            <Button onClick={() => openCreateModal()} >Neue Frage hinzufügen</Button>
                        </SpaceBetween>
                    </Box>
                }
                header={<Header
                    actions={<Button onClick={() => openCreateModal()} >Neue Frage hinzufügen</Button>}
                > Alle Fragen & Erklärungen </Header>}
            />

            <Modal
                onDismiss={() => setVisibleCreate(false)}
                visible={visibleCreate}
                header="Neue Frage hinzufügen"
            >
                <form onSubmit={e => {
                    e.preventDefault()
                    setLoading(true)
                    DatabaseService.createFAQ(question, explanation).then(() => {
                        DatabaseService.init().then(() => {
                            setLoading(false)
                            setVisibleCreate(false)
                        })
                    })
                }}>
                    <Form
                        actions={
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button formAction="none" variant="link" onClick={e => setVisibleCreate(false)}>
                                    Cancel
                                </Button>
                                <Button disabled={loading} variant="primary">{loading ? <Spinner /> : "Save"}</Button>
                            </SpaceBetween>
                        }
                    >
                        <SpaceBetween direction="vertical" size="l">
                            <FormField label="Frage">
                                <Input
                                    onChange={({ detail }) => setQuestion(detail.value)}
                                    value={question}
                                    spellcheck
                                />
                            </FormField>
                            <FormField label="Erklärung">
                                <Textarea
                                    onChange={({ detail }) => setExplanation(detail.value)}
                                    value={explanation}
                                    spellcheck
                                />
                            </FormField>
                        </SpaceBetween>
                    </Form>
                </form>
            </Modal>

            <Modal
                onDismiss={() => setVisibleModify(false)}
                visible={visibleModify}
                header="Frage bearbeiten"
            >
                <form onSubmit={e => {
                    e.preventDefault()
                    setLoading(true)
                    DatabaseService.modifyFAQ(question, explanation, faqid).then(() => {
                        DatabaseService.init().then(() => {
                            setLoading(false)
                            setVisibleModify(false)
                        })
                    })
                }}>
                    <Form
                        actions={
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button formAction="none" variant="link" onClick={e => setVisibleCreate(false)}>
                                    Cancel
                                </Button>
                                <Button disabled={loading} variant="primary">{loading ? <Spinner /> : "Save"}</Button>
                            </SpaceBetween>
                        }
                    >
                        <SpaceBetween direction="vertical" size="l">
                            <FormField label="Frage">
                                <Input
                                    onChange={({ detail }) => setQuestion(detail.value)}
                                    value={question}
                                    spellcheck
                                />
                            </FormField>
                            <FormField label="Erklärung">
                                <Textarea
                                    onChange={({ detail }) => setExplanation(detail.value)}
                                    value={explanation}
                                    spellcheck
                                />
                            </FormField>
                        </SpaceBetween>
                    </Form>
                </form>
            </Modal>
            <Modal
                onDismiss={() => setVisibleDelete(false)}
                visible={visibleDelete}
                header="Begriff löschen"
            >
                <Alert
                    type="warning"
                >Willst du die Frage "{question}" wirklich endgültig löschen? Diese Aktion kann nicht Rückgängig gemacht werden!</Alert>
                <form onSubmit={e => {
                    e.preventDefault()
                    setLoading(true)
                    DatabaseService.deleteFAQ(faqid).then(() => {
                        DatabaseService.init().then(() => {
                            setLoading(false)
                            setVisibleDelete(false)
                        })
                    }) 
                }}>
                    <Form
                        actions={
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button formAction="none" variant="link" onClick={e => setVisibleDelete(false)}>
                                    Cancel
                                </Button>
                                <Button disabled={loading} variant="primary">{loading ? <Spinner /> : "Delete"}</Button>
                            </SpaceBetween>
                        }
                    >
                    </Form>
                </form>
            </Modal>
        </ContentLayout>
    );
}