import { Button, Container, ContentLayout, Grid, Header, SpaceBetween } from "@cloudscape-design/components";
import { AppHeader } from "../../commons/header";
import React from "react";

export default function Processes() {

    return (
        <ContentLayout
            header={
                <AppHeader
                    breadcrumbs={[
                        { text: "CloudFellow", href: "/" },
                        { text: "Onboarding", href: "/onboarding" },
                        { text: "Prozesse", href: "/processes" }
                    ]}
                    header={"Prozesse"}
                    description={"Hier werden alle Prozesse beschrieben, welche benötigst, um in der Cloud zu arbeiten."}
                    actions={null}
                />
            }>
            <Grid
                gridDefinition={[
                    { colspan: 4 }, { colspan: 8 }
                ]}
            >
                <SpaceBetween size={"s"}>
                    <Container
                        header={<Header
                            description="Um in der Cloud starten zu können, musst Du Zugriff auf eine Cloud-Umgebung in unserer Cloud Governance Platform haben. Entweder Deine Kollegen geben Dir Zugriff oder Du befolgst folgende Schritte, um Dir eine neue zu erstellen:"
                        >Neue Cloud Umgebung</Header>}
                        media={{
                            content: (<img src="/images/processes/new_workspace.png" alt="New Workspace"></img>),
                            position: "top",
                            height: "10%"
                        }}
                        footer={<Button
                            href="https://rheinenergie.service-now.com/sp?id=sc_cat_item&sys_id=da476179db5b30100f6e55fbd3961913&sysparm_category=0f484d901b95c8d4f00e0edacd4bcbdd"
                            iconAlign="right"
                            iconName="external"
                        >Hier geht's direkt zu ServiceNow</Button>}
                    >
                        <ol>
                            <li>Öffne das <b>ServiceNow</b> Portal über den untenstehenden Link und navigiere zum Request <b>Neue Cloud Umgebung</b></li>
                            <li>Fülle die Felder wie folgt aus:
                                <ul>
                                    <li><b>Verantwortlicher:</b> Unser Ansprechpartner für die neue Cloud Umgebung</li>
                                    <li><b>Bezeichnung:</b> Der Name der neuen Cloud Umgebung. Dieser sollte fachlich sein und keine Organisations-Namen wie Gruppen oder Abteilungen enthalten</li>
                                    <li><b>Kritikalität:</b> Die Kritikalität der Daten & Applikation, welche in dieser Cloud Umgebung aufgebaut wird</li>
                                    <li><b>Unternehmen:</b> Das Unternehmen, für welches die Cloud Umgebung bereitgestellt wird</li>
                                    <li><b>Business Service:</b> Der Business Service, welchen die Cloud Umgebung unterstützt. Dieser Business Servcie trägt alle anfallenden Kosten aus der Cloud</li>
                                </ul>
                            </li>
                            <li>Sind alle Felder ausgefüllt, so kann die Umgebung bestellt werden. Diese wird <b>vollautomatisiert bereitgestellt</b> und steht Dir nach wenigen Minuten über die <b>Cloud Governance Platform</b> zur Verfügung</li>
                        </ol>
                    </Container>

                </SpaceBetween>
                <SpaceBetween size={"s"}>
                    <Container
                        header={<Header
                            description="Innerhalb einer Cloud Umgebung können mehrere Projects angelegt werden. Dies funktioniert wie folgt:"
                        >Neues Cloud Project</Header>}
                        media={{
                            content: (<img src="/images/processes/new_project.png" alt="New Project"></img>),
                            position: "top",
                            height: "10%"
                        }}
                    >
                        <ol>
                            <li>Navigiere in der <b>Cloud Governance Platform</b> in die Umgebung, in der das neue Project angelegt werden soll</li>
                            <li>Klicke auf den Button <b>Create Project</b></li>
                            <li>Fülle alle Felder wie im Portal beschrieben aus. <b>Wichtig:</b> Bitte gib für den Owner die gesamte Mail-Adresse des Kollegen an</li>
                            <li>Wähle aus welche Cloud Provider Du nutzen willst</li>
                            <li>Bestimme wer welche Zugriffsrechte auf das neu angelegt Project zugewiesen bekommt</li>
                            <li>Überprüfe zum Schluss noch einmal Deine Eingaben und klicke auf den Button <b>Create Project</b></li>
                        </ol>
                    </Container>
                    <Container
                        header={<Header
                            description="Auch für ein bestehendes Cloud Project können im Nachhinein noch Accounts angelegt werden. Befolge dazu einfach folgende Schritte:"
                        >Neuer Cloud Account</Header>}
                        media={{
                            content: (<img src="/images/processes/new_account.png" alt="New Account"></img>),
                            position: "top",
                            height: "10%"
                        }}
                    >
                        <ol>
                            <li>Navigiere in der <b>Cloud Governance Platform</b> in das Project, in dem der neue Account angelegt werden soll</li>
                            <li>Klicke auf den Button <b>Create Tenant</b></li>
                            <li>Wähle aus welche Cloud Provider Du nutzen willst</li>
                            <li>Überprüfe zum Schluss noch einmal Deine Eingaben und klicke auf den Button <b>Create Tenant</b></li>
                        </ol>
                    </Container>
                    <Container
                        header={<Header
                            description="Du kannst sowohl für deine Cloud Umgebungen als auch für die darunterliegenden Cloud Projects selbst alle Zugriffsrechte verwalten. Hier ist beschrieben wo Dies machst und was welche Rolle bedeutet:"
                        >Identity & Access Management</Header>}
                        media={{
                            content: (<img src="/images/processes/idm.png" alt="Identity & Access Management"></img>),
                            position: "top",
                            height: "10%"
                        }}
                    >
                        <ul>
                            <li>Um die Berechtigungen für Deine <b>Cloud Umgebung</b> zu verwalten, öffne den Tab <b>Workspace Access</b> und weise die gewünschte Rolle zu:
                                <ul>
                                    <li><b>Workspace Owner:</b> Hat vollen Zugriff auf den meshWorkspace und seine Projekte und kann den Zugriff auf den meshWorkspace verwalten. Ein Benutzer mit dieser Rolle ist der Ansprechpartner für alle Angelegenheiten im Zusammenhang mit dem meshWorkspace. In einem meshWorkspace kann es maximal zwei Workspace-Inhaber geben. Wenn bereits ein Workspace-Inhaber vorhanden ist, kann dieser Workspace-Inhaber die Rolle „Workspace-Inhaber“ einem anderen Benutzer zuweisen. Wenn kein Workspace-Inhaber vorhanden ist, können die Workspace-Manager einen Workspace-Inhaber zuweisen.</li>
                                    <li><b>Workspace Manager:</b> Hat vollen Zugriff auf den meshWorkspace und seine Projekte und kann den Zugriff auf den meshWorkspace verwalten.</li>
                                    <li><b>Workspace Member:</b> Hat vollen Zugriff auf Projektressourcen, kann jedoch den Zugriff nicht verwalten, keine Projekte erstellen usw. des meshWorkspace.</li>
                                </ul>
                            </li>
                            <li>Um die Berechtigungen für Dein <b>Cloud Project</b> zu verwalten, öffne den Tab <b>Project Access</b> und weise die gewünschte Rolle zu:
                                <ul>
                                    <li><b>Project Admin:</b> AWS IAM-FullAcces+PowerUserAcces / Azure Owner / GCP Editor</li>
                                    <li><b>Project User:</b> AWS PowerUserAccess / Azure Contributor / GCP Editor</li>
                                    <li><b>Project Developer:</b> AWS PowerUserAccess / Azure Contributor / GCP Editor</li>
                                    <li><b>Project Reader:</b> AWS ReadOnlyAccess / Azure Reader / GCP Viewer</li>
                                </ul>
                            </li>
                        </ul>
                    </Container>
                </SpaceBetween>
            </Grid>
        </ContentLayout>
    );
}
