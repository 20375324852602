import React, { useContext } from "react";
import { applyTheme } from '@cloudscape-design/components/theming';
import { theme } from "./themes";
import AppLayout from '@cloudscape-design/components/app-layout';
import { MainNavigation } from "./commons/navigation";
import { Route, Routes } from "react-router-dom";
import { SideNavigationProps, TopNavigation, TopNavigationProps } from "@cloudscape-design/components";
import LoginPage from "./pages/commons/login";
import Error from "./pages/commons/error";
import FAQ from "./pages/faq/faq";
import { auth } from "./commons/firebase";
import { AuthContext } from "./commons/authContext";
import DatabaseService from "./services/databaseservice";
import AdminFAQ from "./pages/faq/admin-faq";
import Overview from "./pages/overview/overview";
import CFP from "./pages/cfp/cfp";
import Glossary from "./pages/glossary/glossary";
import AdminGlossary from "./pages/glossary/admin-glossary";
import Processes from "./pages/processes/processes";
import Pillars from "./pages/pillars/pillars";

applyTheme({ theme })

let pages = [
  { text: 'Overview', href: '/', type: 'link' },
  {
    text: 'Onboarding', type: 'section', items: [
      { text: 'Prozesse', href: '/onboarding/processes', type: 'link' },
      { text: 'Cloud Governance Pillars', href: '/onboarding/pillars', type: 'link' },
      { text: 'Cloud Foundation Platform', href: '/onboarding/cfp', type: 'link' },
      { text: 'Frequently Asked Questions', href: '/onboarding/faq', type: 'link' },
      { text: 'Glossar', href: '/onboarding/glossary', type: 'link' },
    ]
  },
/*   {
    text: 'Platform', type: 'section', items: [
      //{ text: 'Workspaces', href: '/', type: 'link' },
      //{ text: 'Frequently Asked Questions', href: '/faq', type: 'link' },
      //{ text: 'Feature Requests', href: '/feature-requests', type: 'link' },
    ]
  }, */
  /* {
    text: 'Community', type: 'section', items: [
      //{ text: 'Overview', href: '/community/', type: 'link' },
      //{ text: 'Events', href: '/community/events', type: 'link' },
      //{ text: 'Podcasts', href: '/community/podcasts', type: 'link' },
    ]
  }, */
] as SideNavigationProps.Item[]

export default function App() {
  const { currentUser, signOut } = useContext(AuthContext)
  const navigationUtils = []
  let sideNavigation = null;
  let navigationHide = true
  let adminArea = [] as SideNavigationProps.Item[]

  if (currentUser) {
    // pushing profile to top-navigation
    navigationUtils.push({
      type: 'menu-dropdown',
      text: auth.currentUser?.displayName,
      iconName: 'user-profile',
      items: [{
        id: "signout",
        text: "Sign out"
      }],
      onItemClick: (e: { detail: { id: string; }; }) => {
        if (e.detail.id === "signout") {
          signOut()
        }
      }
    })

    //pushing Items to sideNavigation if user is admin
    if (DatabaseService.isUserAdmin(currentUser.uid)) {
      adminArea = [
        { type: "divider" },
        {
          text: 'Administration Area', type: 'section', items: [
            { text: 'Frequently Asked Questions', href: '/admin/faq', type: 'link' },
            { text: 'Glossar', href: '/admin/glossary', type: 'link' },
          ]
        },
      ]
    }

    // modifying side navigation 
    sideNavigation = <MainNavigation items={pages.concat(adminArea)} />
    navigationHide = false
  }

  return (
    <>
      <TopNavigation
        identity={{
          href: '/',
          title: 'CloudFellow',
          logo: { src: process.env.PUBLIC_URL + '/logo.png', alt: 'CloudFellow' },
        }}
        utilities={navigationUtils as readonly TopNavigationProps.Utility[]}
      />
      <AppLayout
        content={
          <Routes>
            {/* only Authenticated Access */}
            <Route path='/' element={currentUser ? <Overview /> : <LoginPage />} />
            <Route path='/onboarding/pillars' element={currentUser ? <Pillars /> : <LoginPage />} />
            <Route path='/onboarding/faq' element={currentUser ? <FAQ /> : <LoginPage />} />
            <Route path='/onboarding/cfp' element={currentUser ? <CFP /> : <LoginPage />} />
            <Route path='/onboarding/glossary' element={currentUser ? <Glossary /> : <LoginPage />} />
            <Route path='/onboarding/processes' element={currentUser ? <Processes /> : <LoginPage />} />
            <Route path='/admin/faq' element={currentUser ? (DatabaseService.isUserAdmin(currentUser.uid) ? <AdminFAQ /> : <Error code={403} />) : <LoginPage />} />
            <Route path='/admin/glossary' element={currentUser ? (DatabaseService.isUserAdmin(currentUser.uid) ? <AdminGlossary /> : <Error code={403} />) : <LoginPage />} />
            <Route path='*' element={currentUser ? <Error code={404} /> : <LoginPage />} />

            {/* only Unauthenticated Access */}
            <Route path='/login' element={currentUser ? <Error code={404} /> : <LoginPage />} />

            {/* both Access */}
          </Routes>
        }
        navigation={sideNavigation}
        navigationHide={navigationHide}
        toolsHide={true}
      />
    </>
  );
}