import { DocumentData, QueryDocumentSnapshot, addDoc, collection, deleteDoc, doc, getDocs, query, setDoc } from "firebase/firestore"
import { db } from "../commons/firebase"

var FAQService: QueryDocumentSnapshot<DocumentData, DocumentData>[] = []
var UserService: QueryDocumentSnapshot<DocumentData, DocumentData>[] = []
var GlossaryService: QueryDocumentSnapshot<DocumentData, DocumentData>[] = []

const DatabaseService = {
    init: async function () {
        UserService = (await getDocs(query(collection(db, "general.user")))).docs
        FAQService = (await getDocs(query(collection(db, "onboarding.faq")))).docs
        GlossaryService = (await getDocs(query(collection(db, "onboarding.glossary")))).docs
    },

    //FAQ
    getFAQ: function () {
        return FAQService
    },
    createFAQ: async function (question: string, explanation: string) {
        await addDoc(collection(db, "onboarding.faq"), {
            question: question,
            explanation: explanation
        })
    },
    modifyFAQ: async function (question: string, explanation: string, id: string) {
        await setDoc(doc(db, "onboarding.faq", id), {
            question: question,
            explanation: explanation
        })
    },
    deleteFAQ: async function (id: string) {
        await deleteDoc(doc(db, "onboarding.faq", id))
    },
    //User
    isUserAdmin: function (userID: string) {
        return UserService.find((user) => user.id === userID)?.data().admin
    },
    createUser: function (user: any) {
        if(UserService.find((item) => item.id === user.uid)){
            console.log("User already known")
            return
        }else {
            setDoc(doc(db, "general.user", user.uid), {
                admin: false,
                email: user.email
            })
        }
    },
    //Glossary
    getGlossaryTerms: function () {
        return GlossaryService.sort((a,b) => {
            if (a.data().term < b.data().term) {
                return -1;
              }
              if (b.data().term < a.data().term) {
                return 1;
              }
              // terms must be equal
              return 0;
        })
    },

    createTerm: async function (term: string, category: any, explanation: string, abbreviation: string, synonyms: string) {
        await addDoc(collection(db, "onboarding.glossary"), {
            term: term,
            category: category,
            explanation: explanation,
            abbreviation: abbreviation,
            synonyms: synonyms.split(",")
        })
    },

    deleteTerm: async function (id: string) {
        await deleteDoc(doc(db, "onboarding.glossary", id))
    },
    modifyTerm: async function (term: string, category: any, explanation: string, abbreviation: string, synonyms: string, id: string) {
        setDoc(doc(db, "onboarding.glossary", id), {
            term: term,
            category: category,
            explanation: explanation,
            abbreviation: abbreviation,
            synonyms: synonyms.split(",")
        })
    },
}

export default DatabaseService