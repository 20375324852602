import { Button, Container, ContentLayout, Header, SpaceBetween } from '@cloudscape-design/components';
import { AppHeader } from '../../commons/header';
import React from 'react';
import { OAuthProvider, getRedirectResult, signInWithPopup } from 'firebase/auth';
import { auth } from '../../commons/firebase';
import { useNavigate } from 'react-router-dom';

export default function LoginPage() {
const navigate = useNavigate();

getRedirectResult(auth)

function signin() {
  const provider = new OAuthProvider("microsoft.com")
  provider.setCustomParameters({
    tenant: "17e9d4a2-1c8d-40d2-af3f-929da470e0c7"
  })

  //signInWithRedirect(auth, provider)
  signInWithPopup(auth, provider).then(() => navigate("/"))
}

 return (
    <ContentLayout
      header={
        <AppHeader
          breadcrumbs={[
            { text: "CloudFellow", href: "/" },
            { text: "login", href: "/login" },
          ]}
          header={"Welcome to CloudFellow"}
          description="allows You to focus on innovation - not infrastructure"
          actions={null}
        />
      }>
      {/* Hier kommt der eigentliche Content hin */}
      <Container
        header={
          <Header
            variant="h2"
            actions={
              <SpaceBetween
                direction="horizontal"
                size="xs"
              >
                <Button variant='primary' onClick={() => signin()}>Sign in</Button>
              </SpaceBetween>
            }
          >
            Sign in to Your RheinEnergie-Account
          </Header>
        }
      />
    </ContentLayout>
  );
};