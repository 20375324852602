import { Badge, Box, ContentLayout, SpaceBetween, Table } from "@cloudscape-design/components";
import { AppHeader } from "../../commons/header";
import React from "react";
import DatabaseService from "../../services/databaseservice";

export default function Glossary() {
    function generateSynonyms(synonyms: any): React.ReactNode {
        return synonyms.map((e: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined) => <Badge>{e}</Badge>)
    }

    return (
        <ContentLayout
            header={
                <AppHeader
                    breadcrumbs={[
                        { text: "CloudFellow", href: "/" },
                        { text: "Onboarding", href: "/onboarding" },
                        { text: "Glossar", href: "/glossary" }
                    ]}
                    header={"Glossar"}
                    description={"Hier findest du alle Begriffe, welche du im Alltag der Public Cloud benötigst! Und wahrscheinlich noch viel mehr..."}
                    actions={null}
                />
            }>
            <SpaceBetween direction="vertical" size="xs">
                <Table
                    wrapLines
                    stripedRows
                    columnDefinitions={[
                        {
                            id: "term",
                            header: "Term",
                            cell: e => e.data().term,
                            width: 130,
                            isRowHeader: true
                        },
                        {
                            id: "category",
                            header: "Kategorie",
                            cell: e => (<Badge>{e.data().category.label}</Badge>),
                            width: 160
                        },
                        {
                            id: "explanation",
                            header: "Erklärung",
                            cell: e => (<Box display="block">{e.data().explanation}</Box>),
                            width: 550
                        },
                        {
                            id: "abbreviation",
                            header: "Abkürzung",
                            cell: e => (<Badge>{e.data().abbreviation}</Badge>),
                            width: 160
                        },
                        {
                            id: "synonyms",
                            header: "Synonyme",
                            cell: e => (<SpaceBetween size={"xxxs"}>{generateSynonyms(e.data().synonyms)}</SpaceBetween>),
                            width: 160
                        }
                    ]}
                    enableKeyboardNavigation
                    items={DatabaseService.getGlossaryTerms()}
                    loadingText="Loading terms"
                />
            </SpaceBetween>

        </ContentLayout>
    );
}