import { ContentLayout } from "@cloudscape-design/components";
import { AppHeader } from "../../commons/header";
import React from "react";

export default function Error (props: {code: number}) {
    var short = ""
    var description = ""

    switch (props.code) {
      case 403:
        short = "forbidden"
        description = "Du bist nicht berechtigt auf diese Seite zuzugreifen. Überprüfe den Link & versuche es erneut!"
        break;

      case 404:
        short = "page not found"
        description = "Diese Seite konnte nicht gefunden werden. Überprüfe den Link & versuche es erneut!"
        break;
    
      default:
        short = "unexpected error occured"
        description = "Ein unerwarteter Fehler ist aufgetreten. Überprüfe den Link & versuche es erneut!"
        break;
    }

    return (
        <ContentLayout 
        header={
          <AppHeader
            breadcrumbs={[
                {text:"CloudFellow", href: "/"},
                {text:"Error", href: "/"}
            ]}
            header={"Error "+props.code+" - " + short}
            description={description}
            actions={null}
          />
        }>
            {/* Hier kommt der eigentliche Content hin */}
        </ContentLayout>
      );
}