import { Container, ContentLayout, ExpandableSection, SpaceBetween } from "@cloudscape-design/components";
import { AppHeader } from "../../commons/header";
import React from "react";
import DatabaseService from "../../services/databaseservice";

export default function FAQ() {
    return (
        <ContentLayout
            header={
                <AppHeader
                    breadcrumbs={[
                        { text: "CloudFellow", href: "/" },
                        { text: "Onboarding", href: "/onboarding" },
                        { text: "FAQ", href: "/faq" }
                    ]}
                    header={"Frequently Asked Questions"}
                    description={"Hier findest du alle Fragen, die uns immer wieder gestellt wurden. Vielleicht helfen sie auch Dir!"}
                    actions={null}
                />
            }>
            <SpaceBetween direction="vertical" size="xs">
                <Container>
                    {generateFAQ()}
                </Container>
            </SpaceBetween>

        </ContentLayout>
    );
}

function generateFAQ() {
    var tempArray: React.JSX.Element[] = []
    DatabaseService.getFAQ().forEach(item => {
        tempArray.push(
            <ExpandableSection
                headerText={item.data().question}
            >
                {item.data().explanation}
            </ExpandableSection>
        )
    })

    return tempArray
}
