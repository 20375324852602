import { Container, ContentLayout, Grid, Header, Link } from "@cloudscape-design/components";
import { AppHeader } from "../../commons/header";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function Overview() {
    const navigate = useNavigate()
    return (
        <ContentLayout
            header={
                <AppHeader
                    breadcrumbs={[
                        { text: "CloudFellow", href: "/" },
                        { text: "Overview", href: "/" }
                    ]}
                    header={"Overview"}
                    description={"Dies ist die CloudFellow-Startseite. Von hier kannst du in alle Bereiche abspringen und alle Informationen zur Public und Private Cloud finden!"}
                    actions={null}
                />
            }>
            <Grid
                gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}
            >
                <Container
                    header={
                        <Header
                            description="Hier findest du alles, was du benötigst um ein neues Projekt in der Cloud zu starten..."
                        >Onboarding</Header>
                    }
                >
                    <ul>
                        <li><Link onFollow={e => navigate("/onboarding/processes")}>Prozesse:</Link> Hier findest du alle Prozesse, welche du benötigst, um bei der RheinEnergie die Reise in die Public Cloud zu starten.</li>
                        <li><Link onFollow={e => navigate("/onboarding/pillars")}>Cloud Foundation Pillars:</Link> Hier werden die verschiedenen Disziplinen der Cloud Governance vorgestellt, wie auch wir bei der RheinEnergie diese anwenden.</li>
                        <li><Link onFollow={e => navigate("/onboarding/cfp")}>Cloud Foundation Platform:</Link> Hier wird ausführlich erklärt, wie unsere Platform funktioniert. Vielleicht ist auch für dich etwas Neues dabei!</li>
                        <li><Link onFollow={e => navigate("/onboarding/faq")}>Frequently Asked Questions:</Link> Hier findest du alle Fragen, die uns immer wieder gestellt wurden. Vielleicht helfen sie auch Dir!</li>
                        <li><Link onFollow={e => navigate("/onboarding/glossary")}>Glossar:</Link> Hier findest du alle Begriffe, die wir immer wieder verwenden. So verstehen wir uns besser!</li>
                    </ul>
                </Container>
            </Grid>
        </ContentLayout>
    );
}
