import React from 'react';
import { useNavigate } from 'react-router-dom';
import SideNavigation, { SideNavigationProps } from '@cloudscape-design/components/side-navigation';

export function MainNavigation(props: { items: readonly SideNavigationProps.Item[] | undefined; }) {
  const navigate = useNavigate()
  return <SideNavigation items={props.items} activeHref={window.location.pathname} onFollow={(event) => {
    event.preventDefault();
    navigate(event.detail.href)
  }} />;
}