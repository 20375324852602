import { Button, Container, ContentLayout, Header, Link, SpaceBetween } from "@cloudscape-design/components";
import { AppHeader } from "../../commons/header";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function CFP() {
    const navigate = useNavigate();

    return (
        <ContentLayout
            header={
                <AppHeader
                    breadcrumbs={[
                        { text: "CloudFellow", href: "/" },
                        { text: "Onboarding", href: "/onboarding" },
                        { text: "CFP", href: "/cfp" }
                    ]}
                    header={"Cloud Foundation Platform"}
                    description={"Hier wird der Aufbau und die einzelnen Module der Cloud Foundation Platform beschrieben."}
                    actions={null}
                />
            }>
            <SpaceBetween direction="vertical" size="xs">
                <Container
                    header={<Header
                        description="Unsere Cloud Foundation Platform bietet dir alle Werkzeuge, damit du dich auf deine Applikation konzentrieren kannst. Sie übernimmt das Management und die Abrechnung der Cloud Umgebung und hilft dir deine Ressourcen anhand standardisierter Prozesse zu verwalten."
                    >Overview</Header>}
                    media={{
                        content: (<img src="/images/cfp/overview.svg" alt="Cloud Governance Platform"></img>),
                        position: "side",
                        width: "30%"
                    }}
                >
                    <ul>
                        <li>Die <b>Cloud Governance Platform</b> hilft dir bei Identity & Access Management, Building Blocks und Abrechnung</li>
                        <li>Das <b>Cloud Security Posture Management</b> hilft dir dabei, deine Cloud Umgebung sicher zu halten</li>
                        <li>Die <b>Automation Platform</b> verwaltet standardisierte Building Blocks, welche per Self-Service zu bestellen sind</li>
                        <li>Die <b>Cloud Learning Platform</b> bietet dir on-demand Trainings zu allen verfügbaren Cloud Providern und weiteren Topics</li>
                        <li>Das <b>FinOps Tooling</b> hilft dir dabei die verwendten Cloud Ressourcen optimal zu nutzen</li>
                        <li>Das <b>Discovery & Asset Management</b> bietet eine automatisierte Datengrundlage für alle weiteren ITSM Prozesse</li>
                    </ul>
                </Container>
                <Container
                    header={<Header
                        description="Die Cloud Governance Platform ist dein Einstiegspunkt, um in der Cloud zu arbeiten. Hier findest du alle deine Workspaces, Projects, Tenants und Building Blocks"
                    >Cloud Governance Platform</Header>}
                    media={{
                        content: (<img src="/images/cfp/governance-platform.svg" alt="Cloud Governance Platform"></img>),
                        position: "side",
                        width: "30%"
                    }}
                    footer={<Button
                        href="https://my.rheinenergie.cloud"
                        iconAlign="right"
                        iconName="external"
                    >Hier geht's direkt zu meshcloud</Button>}
                >
                    <ul>
                        <li>Nachdem du über ServiceNow deinen <b>Workspace</b> angelegt hast, bekommst Du zugriff auf meshcloud und kannst <b>Projects</b> anlegen</li>
                        <li>In einem <b>Project</b> bündelst Du deine Tenants aus allen Providern, Kubernetes Clustern & Instanzen aus den <b>Building Blocks</b></li>
                        <li>Um deine <b>Tenants</b> möglichst gut managen zu können, ist eine sinnvolle Struktur notwendig</li>
                        <li>Ein <b>Project</b> sollte <b>immer</b> nur eine Staging-Umgebung (dev/test/prod) abbilden</li>
                        <li>Wenn alle Staging-Umgebungen Zugriff auf <b>gemeinsame Ressourcen</b> benötigen, sollte zusätzlich ein <b>SharedServices-Project</b> eingerichtet werden</li>
                        <li>Alle Informationen zu den aktuell verfügbaren <b>Building Blocks</b> findest du <Link onFollow={e => navigate("/todo")}>hier</Link></li>
                        <li>Alle Ressourcen werden automatisiert an den angegebenen <b>Business Service</b> verrechnet</li>
                        <li>Über die <b>Projects</b> steuerst Du außerdem, welche Kollegen Zugriff auf deine <b>Tenants</b> haben</li>
                    </ul>
                </Container>
                <Container
                    header={<Header
                        description="Das Cloud Cloud Security Posture Management kennt alle deine Cloud Ressourcen und bewertet diese anhand ihrer Metadaten und Inhalte hinsichltich ihrer Sicherheit"
                    >Cloud Security Posture Management</Header>}
                    media={{
                        content: (<img src="/images/cfp/security-posture-management.svg" alt="Cloud Governance Platform"></img>),
                        position: "side",
                        width: "30%"
                    }}
                    footer={<Button
                        href="https://app.wiz.io/"
                        iconAlign="right"
                        iconName="external"
                    >Hier geht's direkt zu wiz.io</Button>}
                >
                    <ul>
                        <li>Alle <b>Projects</b>, welche du in der Cloud Governance Platform anlegst, werden automatisiert mit den dazugehörigen <b>Tenants</b> nach wiz.io synchronisiert</li>
                        <li>Alle Berechtigungen, welche du in meshcloud setzt, werden automatisiert als Reader oder Member nach wiz.io synchronisiert</li>
                        <li>wiz.io inventarisiert alle Ressourcen aus deinen <b>Tenants</b> und bewertet deren Sicherheit anhand verschiedener <b>Frameworks</b></li>
                        <li>Treten bei einer Ressource bestimmte oder mehrere <b>Findings</b> auf, so wird für diese ein <b>Issue</b> erstellt, welches einer der Kritikalitäten <b>Low, Medium, High</b> oder <b>Critical</b> zugeordnet wird</li>
                        <li>Für jedes <b>Critical Issue</b> wird ein Incident in ServiceNow erstellt und dem Owner des Projects zugeordnet</li>
                        <li>Wird ein <b>Issue</b> gelöst, so wird dieses nach dem nächsten Scan geschlossen. Die Scans laufen einmal täglich</li>
                    </ul>
                </Container>
                <Container
                    header={<Header
                        description="Mit der Automation Platform werden die Building Blocks in den jeweiligen Ziel-Platformen bereitgestellt und verwaltet. Sie kann auch verwendet werden, um deine Applikation automatisiert bereitzustellen."
                    >Automation Platform</Header>}
                    media={{
                        content: (<img src="/images/cfp/automation-platform.svg" alt="Cloud Governance Platform"></img>),
                        position: "side",
                        width: "30%"
                    }}
                >
                    <ul>
                        <li>Alle <b>Building Blocks</b> aus meshcloud oder ServiceNow werden automatisiert durch diese Platform deployed</li>
                        <li>In <b>Stacks</b> können Workflows verschiedenster Automatisierungs-Tools nacheinander in der richtigen Reihenfolgen ausgeführt und Variablen zwischen diesen übergeben werden</li>
                        <li>Wenn Du Ideen für neue wiederverwendbare <b>Building Blocks</b> hast melde dich gerne bei uns unter <Link onFollow={e => window.location.href = "mailto:cloud@rheinenergie.com"}>cloud@rheinenergie.com</Link></li>
                    </ul>
                </Container>
                <Container
                    header={<Header
                        description="Die Cloud Learning Platform bietet unzählige Lessons zu allen Cloud Providern und weiteren grundlegenden IT-Themen. Sie wird als Building Block angeboten und kann jederzeit bestellt und genutzt werden."
                    >Cloud Learning Platform</Header>}
                    media={{
                        content: (<img src="/images/cfp/learning-platform.svg" alt="Cloud Governance Platform"></img>),
                        position: "side",
                        width: "30%"
                    }}
                    footer={<Button
                        href="https://learn.acloud.guru/"
                        iconAlign="right"
                        iconName="external"
                    >Hier geht's direkt zu Cloud Guru</Button>}
                >
                    <ul>
                        <li>Online On-Demand <b>Learning-Platform</b></li>
                        <li>Die Platform bietet Vorbereitungskurse für diverse Zertifizierungen</li>
                        <li>Trainings für <b>alle Hyperscaler</b> sowie Linux, Container & Automatisierung</li>
                        <li><Link onFollow={e => navigate("/todo")}>Hier</Link> ist beschrieben wir du deinen Zugang bekommst</li>
                    </ul>
                </Container>
                <Container
                    header={<Header
                        description="Das FinOps Tooling kann dir dabei helfen, deine Cloud Ressourcen optimal zu nutzen. Es kennt alle deine Cloud-Umgebungen und hilft uns dabei, Dich in FinOps Fragestellungen zu beraten."
                    >FinOps Tooling</Header>}
                    media={{
                        content: (<img src="/images/cfp/finops-tooling.svg" alt="Cloud Governance Platform"></img>),
                        position: "side",
                        width: "30%"
                    }}
                >
                    <ul>
                        <li>Alle <b>Projects</b>, welche du in der Cloud Governance Platform anlegst, werden automatisiert mit den dazugehörigen <b>Tenants</b> in den Multi Cloud Accelarator synchronisiert</li>
                        <li>Deine Workspaces werden als <b>Application</b> angelegt und die dazugehörigen Projects als <b>Environment</b></li>
                        <li>Zusätzlich wird über die Applications auch unser <b>Service-Modell</b> abgebildet und alle Cloud-Umgebungen auch dem jeweiligen Business Service zugewiesen</li>
                        <li>Der Mutli Cloud Accelarator inventarisiert alle Ressourcen aus deinen <b>Tenants</b> und bewertet deren Kosten/Nutzen Verhältnis anhand verschiedener <b>Frameworks</b></li>
                        <li>Findet das Tool ein Optimierungspotential so wird eine <b>Savings Suggestion</b> angelegt</li>
                        <li>Anhand dieser Suggestions wird das Cloud Competence Center auf dich zukommen und die bei der Optimierung deiner Ressourcen <b>beratend</b> zur Seite stehen</li>
                    </ul>
                </Container>
                <Container
                    header={<Header
                        description="Das Discovery & Asset Management hilft dir dabei ITSM-Prozesse auch in der Cloud anwendbar zu machen. Alle deine Cloud Ressourcen sind in der CMDB für die weiteren Prozesse gepflegt."
                    >Discovery & Asset Management</Header>}
                    media={{
                        content: (<img src="/images/cfp/data-asset-management.svg" alt="Cloud Governance Platform"></img>),
                        position: "side",
                        width: "30%"
                    }}
                >
                    <ul>
                        <li>Alle <b>Projects</b>, welche du in der Cloud Governance Platform anlegst, werden automatisiert mit den dazugehörigen <b>Tenants</b> nach Qbilon synchronisiert</li>
                        <li>Qbilon inventarisiert alle Ressourcen aus deinen <b>Tenants</b> und analysiert deren <b>Metadaten</b>, nicht deren Inhalte</li>
                        <li>Sobald alle Ressourcen importiert wurden, analysiert der <b>Linker</b> diese und erkennt <b>Relationen</b> zwischen diesen anhand der Metadaten & nimmt diese ins Inventar mit auf</li>
                        <li>Wurden alle Ressourcen analysiert, werden diese und die dazugehörigen Attribute in die <b>ServiceNow CMDB</b> synchronisiert</li>
                        <li>Jetzt stehen uns allen die Configuration Items bereit, sodass diese in den anderen ITSM Prozessen genutzt werden können</li>
                    </ul>
                </Container>
            </SpaceBetween>
        </ContentLayout>
    );
}
